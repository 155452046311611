import _ from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
    Button,
    Center,
    Checkbox,
    CloseButton,
    HStack,
    Input,
    VStack
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'

import { baseUrl } from '../env'

import type { IsoDate } from '../types/branded'
import { StateUser } from '../types/utility'

const fetchChecklist = async ({ setDate, setUse, setChecklist} :
    { setDate: StateUser<IsoDate|null>, setUse: StateUser<'morning'|'evening'>, setChecklist: StateUser<string> },
    use?: string
) => {
    const intendedUse = use || (dayjs().hour() >= 5 && dayjs().hour() < 15 ? 'morning' : 'evening')
    const response = await fetch(`${baseUrl}/checklists?use=${intendedUse}`)
    const data = await response.json()
    setDate(data.date)
    setUse(data.use)

    let useLocalData = false
    const localDataJson = window.localStorage.getItem('checklist')
    if (localDataJson) {
        const localData = JSON.parse(localDataJson)
        if (localData.date === data.date && localData.use === data.use) {
            // The actual case where DB data is fresher is a change of device or browser;
            // DB data will inherently be updated slightly after localStorage on the same browser
            const localDataIsFresher = dayjs(localData.updatedAt).isAfter(dayjs(data.updatedAt).subtract(10, 'seconds'))
            if (localDataIsFresher) {
                useLocalData = true
                setChecklist(localData.checklist)
                fetch(`${baseUrl}/checklists`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        date: data.date,
                        use: data.use,
                        checklist: localData.checklist
                    })
                })
            }
        }
    }

    if (!useLocalData) {
        setChecklist(data.checklist)
    }
}

export default function Checklist() {
    const [checklist, setChecklist] = useState('')
    const [newItem, setNewItem] = useState('')
    const [date, setDate] = useState<IsoDate|null>(null)
    const [use, setUse] = useState<'morning'|'evening'>('morning')
    const oppositeUse = use === 'morning' ? 'evening' : 'morning'

    const updateChecklistInDb = async (updatedChecklist: string) => {
        return fetch(`${baseUrl}/checklists`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                date,
                use,
                checklist: updatedChecklist
            })
        })
    }
    const update = _.debounce(updateChecklistInDb, 2000, { leading: true })
    const fullUpdate = async (updatedChecklist: string) => {
        window.localStorage.setItem('checklist', JSON.stringify({
            date,
            use,
            checklist: updatedChecklist,
            updatedAt: dayjs().toISOString()
        }))
        setChecklist(updatedChecklist)
        update(updatedChecklist)
    }

    useEffect(() => { fetchChecklist({ setDate, setUse, setChecklist }) }, [])

    const handleCheck = (item: string, status: string) => {
        const uncheckedLine = `${item}:☐`
        const checkedLine = `${item}:☑`
        const ignoredLine = `${item}:☒`
        let updatedChecklist
        if (status === '☐') {
            updatedChecklist = checklist.replace(uncheckedLine, checkedLine)
        } else if (status === '☑') {
            updatedChecklist = checklist.replace(checkedLine, ignoredLine)
        } else if (status === '☒') {
            updatedChecklist = checklist.replace(ignoredLine, uncheckedLine)
        } else {
            throw new Error(`Unknown checklist item status: ${item}, ${status}`)
        }

        fullUpdate(updatedChecklist)
    }

    const addChecklistItem = () => {
        if (!newItem.trim()) {
            setNewItem('') // Reset the input box
            return
        }
        const updatedChecklist = `${checklist};${newItem}:☐`
        fullUpdate(updatedChecklist)
        setNewItem('')
    }

    const removeChecklistItem = (item: string) => {
        const escapedItem = item.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        const updatedChecklist = checklist
        // (?:^|;) is "match if it's either the start of the string
        // or if it's preceded by a semicolon (and maybe a newline),
        // but don't replace the preceding semicolon/newline"
        .replace(new RegExp(`(?:^|;\n?)${escapedItem}:[☐☑☒]`), '')
        fullUpdate(updatedChecklist)
    }

    const checkboxStack = <VStack spacing={2} align='left'>
        {checklist.split(';').map(line => {
            const [item, status] = line.split(':').map(l => l.trim()).filter(l => l.trim())
            const checked = status === '☑' || status === '☒'
            const ignored = status === '☒'

            return (
                <Checkbox
                    key={item}
                    icon={ignored ? <CloseIcon /> : <CheckIcon />}
                    isChecked={checked}
                    onChange={() => handleCheck(item, status)}
                    colorScheme={ignored ? 'gray' : 'green'}
                >
                    <HStack>
                        <div>{ignored ? <s>{item}</s> : item}</div>
                        <CloseButton
                            size='sm'
                            onClick={() => removeChecklistItem(item)}
                        />
                    </HStack>
                </Checkbox>
            )
        })}
        <HStack>
            <Input value={newItem}  placeholder='New item here'
                size='md' width={150}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setNewItem(event.currentTarget.value)} />
            <Button
                isDisabled={!newItem.length}
                onClick={addChecklistItem}
            >
                Add
            </Button>
        </HStack>
        <Button
            size='sm'
            onClick={() => fetchChecklist({ setDate, setUse, setChecklist }, oppositeUse)}
        >
            See {oppositeUse}
        </Button>
    </VStack>
      
    return <Center> { !checklist ? "You're trouble." : checkboxStack } </Center>
}

