import { useEffect, useState } from 'react'

import {
    Box,
    Button,
    Card,
    CardBody,
    Checkbox,
    Flex,
    HStack,
    Input,
    Select,
    Switch,
    Text,
    VStack
} from '@chakra-ui/react'
import TimePicker from 'react-time-picker'

import { baseUrl } from '../env'
import dayjs from 'dayjs'
import { manualPositionHandler, getFriendlyLocationForSub } from '../util/location'

import { IsoTime, TaskOccurrenceId } from '../types/branded'
import { TaskOccurrence } from '../types/models'
import { StateUser } from '../types/utility'

const settingToText = {
    showTasksTab: 'Show Tasks tab',
    showSupertasksTab: 'Show Supertasks tab',
    showChecklistsTab: 'Show Checklists tab',
    showRemindersTab: 'Show Reminders tab',
    showHistoryTab: 'Show History tab',
    showRewardsTab: 'Show Rewards tab',
    showPunishmentsTab: 'Show Punishments tab',
    showOrdersTab: 'Show Orders tab',
    showAdminTab: 'Show Admin tab'
}
type Setting = keyof typeof settingToText
type Mute = {
    id?: string,
    start: IsoTime | null,
    end: IsoTime | null,
    taskOccurrenceId: TaskOccurrenceId | null
}
type ManualLocation = {
    id: string,
    text: string,
    json: {
        latitude: number,
        longitude: number,
        friendlyLocation: string | null,
        addedByDom: boolean
    }
    createdAt: IsoTime
}

export default function Settings({ settings, setSettings, serverSettings, tabIndex, setTabIndex }:
{
    settings: Record<string, boolean>,
    setSettings: StateUser<Record<string, boolean>>,
    serverSettings: Record<string, any>,
    tabIndex: number,
    setTabIndex: StateUser<number>
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)
    const [showOccurrencesSelect, setShowOccurrencesSelect] = useState(false)
    const [occurrences, setOccurrences] = useState([] as TaskOccurrence[])
    const [muteData, setMuteData] = useState(
        { start: dayjs().toISOString(), end: null, taskOccurrenceId: null } as Mute)
    const [existingMutes, setExistingMutes] = useState([] as Mute[])
    const [locationComment, setLocationComment] = useState('')
    const [locations, setLocations] = useState([] as ManualLocation[])
    const [censorSub, setCensorSub] = useState(serverSettings.censored as boolean | undefined || false)
    const [censorAdmin, setCensorAdmin] = useState(serverSettings.censoredAdmin as boolean | undefined || false)
    const adminView = localStorage.getItem('adminView') === 'true'

    useEffect(() => {
        const fn = async () => {
            const [occurrencesData, existingMutesData] = await Promise.all([
                fetch(`${baseUrl}/tasks/occurrences/simple`).then(res => res.json()),
                fetch(`${baseUrl}/adhoc?type=mute`).then(res => res.json())
            ])
            setOccurrences(occurrencesData.filter(
                (occurrence: TaskOccurrence) => !occurrence.completedAt &&
                occurrence.date === dayjs().format('YYYY-MM-DD')
            ))
            setExistingMutes(existingMutesData.map((muteData: any) => ({
                id: muteData.id,
                start: muteData.json.start,
                end: muteData.json.end,
                taskOccurrenceId: muteData.json.taskOccurrenceId
            })))
        }

        fn()
    }, [])

    const updateMutes = async () => {
        const mutes = await fetch(`${baseUrl}/adhoc?type=mute`).then(res => res.json())
        setExistingMutes(mutes.map((muteData: any) => ({
            id: muteData.id,
            start: muteData.json.start,
            end: muteData.json.end,
            taskOccurrenceId: muteData.json.taskOccurrenceId
        })))
    }

    const handleButtonPress = async () => {
        setIsLoading(true)
        const response = await fetch(`${baseUrl}/adhoc`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'mute',
                json: muteData
            })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setMuteData({
                start: dayjs().toISOString(),
                end: null,
                taskOccurrenceId: null
            })
            setShowOccurrencesSelect(false)
            updateMutes()
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    const updateSettings = (key: string) => {
        const currentSetting = settings[key as Setting]
        const newTabIndex = key === 'showAdminTab' ?
            tabIndex :
            tabIndex + (currentSetting ? -1 : 1)
        setSettings({ ...settings, [key]: !settings[key] })
        setTabIndex(newTabIndex)
    }

    return (
        <Box>
            <VStack align='left'>
                {Object.entries(settings).map(([key, value]) => (
                    <Flex key={key}>
                        <Checkbox
                            isChecked={value}
                            onChange={() => updateSettings(key)}
                        />
                        <Text marginLeft='8px'>{settingToText[key as Setting] || key}</Text>
                    </Flex>
                ))}
                <Flex key='censor-sub'>
                    <Checkbox
                        isChecked={censorSub}
                        onChange={async () => {
                            if (censorSub) {
                                setCensorSub(false)
                                const settings = await fetch(`${baseUrl}/adhoc?type=setting`)
                                .then(response => response.json())
                                
                                const setting = settings.find((setting: any) => setting.text === 'censored=true')
                                await fetch(`${baseUrl}/adhoc?id=${setting.id}`, {
                                    method: 'DELETE'
                                })
                                return
                            } else {
                                setCensorSub(true)
                                await fetch(`${baseUrl}/adhoc`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        type: 'setting',
                                        text: 'censored=true'
                                    })
                                })
                                return
                            }
                        }}
                    />
                    <Text marginLeft='8px'>Censor</Text>
                </Flex>
                { adminView ? <Flex key='censor-admin'>
                    <Checkbox
                        isChecked={censorAdmin}
                        onChange={async () => {
                            if (censorAdmin) {
                                setCensorAdmin(false)
                                const settings = await fetch(`${baseUrl}/adhoc?type=setting`)
                                .then(response => response.json())
                                
                                const setting = settings.find((setting: any) => setting.text === 'censoredAdmin=true')
                                await fetch(`${baseUrl}/adhoc?id=${setting.id}`, {
                                    method: 'DELETE'
                                })
                                return
                            } else {
                                setCensorAdmin(true)
                                await fetch(`${baseUrl}/adhoc`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        type: 'setting',
                                        text: 'censoredAdmin=true'
                                    })
                                })
                                return
                            }
                        }}
                    />
                    <Text marginLeft='8px'>Censor (Admin)</Text>
                </Flex> : null}
                <Card >
                <CardBody>
                <VStack float='left' spacing='4'>
                    <Text>Start time: </Text>
                    <Text>End time: </Text>
                    <Text>Pick occurrence:</Text>
                    {showOccurrencesSelect ? <Text>Occurrence: </Text> : null}
                </VStack>
                <VStack float='left' spacing='3' marginLeft='10px'>
                <TimePicker
                    value={(muteData.start ? dayjs(muteData.start) : dayjs()).format('HH:mm')}
                    shouldOpenClock={() => false}
                    clockIcon={null}
                    clearIcon={null}
                    maxDetail='minute'
                    onChange={time => {
                        if (!time) return
                        // TimePicker values are e.g. 12:30
                        const [hour, minute] = time.split(':').map(Number)
                        const isoTime = dayjs().hour(hour).minute(minute).toISOString()
                        setMuteData({ ...muteData, start: isoTime })
                    }
                    }
                />
                <TimePicker
                    value={muteData.end ? dayjs(muteData.end).format('HH:mm') : ''}
                    shouldOpenClock={() => false}
                    clockIcon={null}
                    clearIcon={null}
                    maxDetail='minute'
                    onChange={time => {
                        if (!time) return
                        // TimePicker values are e.g. 12:30
                        const [hour, minute] = time.split(':').map(Number)
                        const isoTime = dayjs().hour(hour).minute(minute).toISOString()
                        setMuteData({ ...muteData, end: isoTime })
                    }}
                />
                <Switch
                    isChecked={showOccurrencesSelect}
                    onChange={() => setShowOccurrencesSelect(!showOccurrencesSelect)}
                    marginTop='10px'
                />
                {!showOccurrencesSelect ? null :
                <Select
                    id='occurrence'
                    onChange={(event) => {
                        setMuteData({ ...muteData, taskOccurrenceId: event.target.value as TaskOccurrenceId })
                    }}
                    maxWidth='100px'
                >
                    <option value=''>Select</option>
                    {occurrences.map(occurrence => (
                        <option
                        key={occurrence.id}
                        value={occurrence.id}
                        >
                        {occurrence.task.name}
                        </option>
                    ))}
                </Select>
                }
                </VStack>
                </CardBody>
                <Button
                    isDisabled={
                        (!!muteData.end && dayjs(muteData.end).isBefore(muteData.start))
                        || (!muteData.end && !muteData.taskOccurrenceId)
                    }
                    isLoading={isLoading}
                    colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                    onClick={handleButtonPress}
                >
                    {didSucceed ? 'Created mute' : didError ? 'Failed to create mute' : 'Create mute' }
                </Button>
                </Card>
                { existingMutes.length === 0 ? null :
                    <VStack align='left'>
                    <Text>Existing mutes:</Text>
                    {existingMutes.map(mute => (
                        <HStack>
                            <Text key={mute.id}>
                                From {dayjs(mute.start).tz().format('h:mm A')} {mute.end ? '- ' + dayjs(mute.end).tz().format('h:mm A') : ' on'}
                                {mute.taskOccurrenceId ? `, for ${occurrences.find(o => o.id === mute.taskOccurrenceId)!.task.name}` : ''}
                            </Text>
                            <Button
                                size='sm'
                                onClick={async () => {
                                    const response = await fetch(`${baseUrl}/adhoc?id=${mute.id}`, {
                                        method: 'DELETE'
                                    })
                                    if (response.ok) {
                                        updateMutes()
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </HStack>
                    ))}
                    </VStack>
                }
                <HStack maxWidth='400px' marginTop='10px'>
                    <Input
                        placeholder="Note about location"
                        value={locationComment}
                        onChange={e => setLocationComment(e.target.value)}
                    />
                    <Button
                        colorScheme={!locationComment ? 'gray' :
                            locationComment === 'Failed to send location' ? 'red' : 'blue'
                        }
                        isDisabled={!locationComment}
                        onClick={() => {
                            const location = navigator.geolocation
                            if (!location) {
                                alert('Location not working - tell Sam')
                                return
                            }
                            location.getCurrentPosition(
                                async position => {
                                    const succeeded = await manualPositionHandler(
                                        position, locationComment, adminView)
                                    if (succeeded) {
                                        setLocationComment('')
                                    } else {
                                        setLocationComment('Failed to send location')
                                    }
                                },
                                error => {
                                    alert('Failed to get location - tell Sam')
                                    console.error(error)
                                }
                            )
                        }}
                    >
                        Locate
                    </Button>
                </HStack>
                <Button
                    onClick={() => {
                        if (!locations.length) {
                            fetch(`${baseUrl}/adhoc?type=location-manual`)
                            .then(response => response.json())
                            .then(data => {
                                console.log(data)
                                setLocations(data)
                            })
                        } else {
                            setLocations([])
                        }
                    }}
                >
                    {!locations.length ? 'Show' : 'Hide'} locations
                </Button>
                {
                    locations.map(location => {
                        const lat = Number(location.json.latitude).toFixed(4).toString()
                        const lng = Number(location.json.longitude).toFixed(4).toString()
                        return (
                        <div key={location.id}>
                            {dayjs(location.createdAt).format('dddd, MM/D [at] h:mm A: ')}
                            <a href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
                                target="_blank" rel="noreferrer"
                            >{getFriendlyLocationForSub(location.json.friendlyLocation) || lat + ', ' + lng}
                            </a>
                            {' - "' + location.text + '"'}
                        </div>
                    )})
                }
            </VStack>
        </Box>
    )
}