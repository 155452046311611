import { useEffect, useState } from 'react'

import _ from 'lodash'
import {
    Accordion,
    Card,
    CardBody,
    CardHeader,
    Center,
    FormControl,
    FormLabel,
    Heading,
    Switch,
    VStack,
} from '@chakra-ui/react'

import AccordionItemBoilerplate from './helpers/AccordionItemBoilerplate'
import dayjs from '../dayjs'
import { baseUrl } from '../env'
import categorizeRewards from '../util/categorizeRewards'

import type { StatsData } from '../types/other'

export default function RewardStats() {
    const placeholder = {
        pointsEarned: NaN,
        pointsSpent: NaN,
        pointsAvailable: NaN,
        bySourcePoints: [],
        rewardsBought: []
    }
    const [stats, setStats] = useState<StatsData.RewardStats>(placeholder)
    const [includeUsed, setIncludeUsed] = useState(false)
    const [useCategorical, setUseCategorical] = useState(false)

    useEffect(() => {
      const fn = async () => {
        const response = await fetch(`${baseUrl}/rewards/stats`)
        const data = await response.json()
        setStats(data)
      }

      fn()
    }, [])

    const bySourcePoints = stats.bySourcePoints.map(bySingleSourcePoints => {
        const source = bySingleSourcePoints.source
        return {
            ...bySingleSourcePoints,
            source: source && source[0].toUpperCase() + source.slice(1)
        }
    })
    .filter(bySourcePoints => includeUsed || bySourcePoints.unused > 0)
    const [recurringTaskRewards, oldRecurringTaskRewards] = _(bySourcePoints)
    .filter('isRecurringTask')
    .partition('isDeletedTask')
    .reverse()
    .value()

    const [nonRecurringTaskRewards, standaloneRewards] = _(bySourcePoints)
    .reject('isRecurringTask')
    .partition('isStandaloneReward')
    .reverse()
    .value()

    const mapRewardToCard = (reward: StatsData.Reward) => {
        const { rewardType } = reward
        let discount = ''
        if (rewardType === 'qp$' ||
            rewardType === 'Special grocery reward' ||
            rewardType === '$ (March 2023)'
        ) {
            discount = '(quarter-price)'
        } else if (rewardType === 'hp$') {
            discount = '(half-price)'
        } else if (rewardType === 'Craft supplies ($)') {
            discount = '(3/4ths price)'
        }
        return (
        <AccordionItemBoilerplate
            buttonText={`${reward.comment}: ${reward.pointsSpent} points ${discount}`}
            panelContent={
                <Card>
                    <CardHeader style={{ paddingBottom: '0px' }}>
                        <Heading size="sm">{reward.comment}</Heading>
                    </CardHeader>
                    <CardBody>
                        {reward.rewardType !== 'Childhood photo' ? `$${reward.rewardQuantity}, ` : null}{reward.pointsSpent} points <br />
                        Date: {dayjs(reward.createdAt).tz('America/Los_Angeles').format('MMMM D, YYYY')}
                    </CardBody>
                </Card>
            }
        />)
    }

    return Number.isNaN(stats.pointsEarned) ?
        (<Center>{'Gathering catnip...'}</Center>) :
    (
        <VStack align='stretch'>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor='rewards-display' mb='0'>
                    Include spent points
                </FormLabel>
                <Switch
                    id='rewards-display'
                    onChange={e => setIncludeUsed(e.target.checked)}
                />
            </FormControl>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="lg">Totals</Heading>
                </CardHeader>
                <CardBody>
                    Available: {stats.pointsAvailable}
                    { includeUsed ? (
                            <div>
                                Spent: {stats.pointsSpent}<br />
                            </div>
                        ): <div><br /></div>
                    }
                </CardBody>
            </Card>
            <br />
            <div>Point sources:</div>
            <Accordion allowToggle={true}>
                <RewardSourceAccordion label='Recurring tasks' bySourcePoints={recurringTaskRewards} includeUsed={includeUsed} />
                <RewardSourceAccordion label='Non-recurring tasks' bySourcePoints={nonRecurringTaskRewards} includeUsed={includeUsed} />
                <RewardSourceAccordion label='Standalone rewards' bySourcePoints={standaloneRewards} includeUsed={includeUsed} />
                <RewardSourceAccordion label='Old recurring tasks' bySourcePoints={oldRecurringTaskRewards} includeUsed={includeUsed} />
            </Accordion>
            <br />
            <div>Rewards bought:</div>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor='rewards-display' mb='0'>
                    Use categorical display
                </FormLabel>
                <Switch
                    id='rewards-display'
                    onChange={e => setUseCategorical(e.target.checked)}
                />
            </FormControl>
            { !useCategorical ? 
            <Accordion allowToggle={true}> 
                {_(stats.rewardsBought).orderBy('createdAt', 'desc').value().map(mapRewardToCard)}
            </Accordion> :
            <Accordion allowToggle={true}>
                {categorizeRewards(stats.rewardsBought).map(([category, rewards]) => {
                    const categoryPointSum = _.sumBy(rewards, 'pointsSpent')
                    return (
                    <AccordionItemBoilerplate
                        buttonText={`${category}: ${rewards.length} rewards (${(categoryPointSum / 1000).toFixed(1)}k points)`}
                        panelContent={
                            <Accordion allowToggle={true}>
                                {rewards.map(mapRewardToCard)}
                            </Accordion>
                        }
                    />
                )})}
            </Accordion>}
        </VStack>
    )
}

function RewardSourceAccordion({ label, bySourcePoints, includeUsed } :
    { label: string, bySourcePoints: StatsData.BySingleSourcePoints[], includeUsed: boolean }) {
    const makeQuantityText = (bySourcePoints: StatsData.BySingleSourcePoints[]) => {
        const unused = _.sumBy(bySourcePoints, 'unused')
        const ever = _.sumBy(bySourcePoints, 'total')
        const everText = includeUsed ? ` unused, ${ever} ever` : ''
        return unused + everText
    }

    return !bySourcePoints.length ? null : (
        <AccordionItemBoilerplate
            buttonText={`${label}: ${makeQuantityText!(bySourcePoints)}`}
            panelContent={
                <Accordion allowToggle={true}>
                    {bySourcePoints.map(bySingleSourcePoints => <AccordionTaskItem
                        bySingleSourcePoints={bySingleSourcePoints}
                        includeUsed={includeUsed}
                        key={bySingleSourcePoints.source}
                    />)}
                </Accordion>
            }
        />
    )
}

function AccordionTaskItem({ bySingleSourcePoints, includeUsed }: { bySingleSourcePoints: StatsData.BySingleSourcePoints, includeUsed: boolean }) {
    const {
        source,
        total,
        used,
        unused
    } = bySingleSourcePoints
    return (
        <AccordionItemBoilerplate
        buttonText={`${source}: ${unused}` + (includeUsed ? ` unused, ${total} ever`: '')}
        panelContent={
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="sm">{source}</Heading>
                </CardHeader>
                <CardBody>
                    Unused: {unused}<br />
                    { includeUsed ? (
                        <div>
                                Spent: {used}<br />
                                Total: {total}
                            </div>
                        ): null
                    }
                </CardBody>
            </Card>
        } />
    )
}